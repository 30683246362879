import useMediaQuery from "@material-ui/core/useMediaQuery";
import {Col, Container, Row} from "react-bootstrap";
import utilStyles from "styles/utils.module.scss";
import classNames from "classnames";
import styles from "components/ProductGrid.module.scss";
import ProductListCard from "components/cards/ProductListCard";


const ProductGrid = (props) => {
    const {products, showRank, referralInfo} = props;
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);
    const onProductClick = props.onProductClick || function () {
    };

    return (
        // <Container>
        //     {
        //         chunkedProducts.map((chunk, idx1) =>
        //             <div className={utilStyles.flexRow} key={chunk.reduce((p, c) => !!c ? p + c.id: '', '')}>
        //                 <Row className={classNames(utilStyles.fullFlex, styles.row)}>
        //                     {
        //                         chunk.map((product, idx2) =>
        //                             <Col xs={6} lg={isLarge ? 4 : 6} xl={3} className={styles.col} key={!!product ? '000' + product.id.toString() : idx2}>
        //                                 {
        //                                     !!product &&
        //                                     <ProductListCard product={product} />
        //                                 }
        //                             </Col>
        //                         )
        //                     }
        //                 </Row>
        //             </div>
        //         )
        //     }
        // </Container>
        <Container>
            <div className={utilStyles.flexRow}>
                <Row className={classNames(utilStyles.fullFlex, styles.row)}>
                    {
                        products.map((product, idx) => {
                            return (
                                <Col key={product.id} xs={6} lg={isLarge ? 4 : 6} xl={isLarge ? 3 : 6}
                                     className={styles.col}>
                                    <ProductListCard product={product} showRank={showRank} referralInfo={referralInfo}
                                                     onClick={() => onProductClick(product, idx)}/>
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
        </Container>
    );
};

export default ProductGrid;
