import classNames from "classnames";
import utilStyles from 'styles/utils.module.scss';
import styles from "pages/store/products/index.module.scss";
import {useContext, useEffect, useState, useRef, useLayoutEffect} from "react";
import {Axios, defaultCacheOption} from "api";
import {numberWithComma} from "common/utils";
import Paginator from "components/Paginator";
import Loading from "components/Loading";
import NavigationLayout from "components/layout/NavigationLayout";
import {useRouter} from "next/router";
import MainBanner from "components/sections/MainBanner";
import Link from "next/link";
import ArrowRightIcon from "images/icons/arrow_right.svg";
import TreatmentIcon from "images/icons/treatment.svg";
import HairEssenceIcon from "images/icons/hair_essence.svg";
import MaskPackIcon from "images/icons/maskpack.svg";
import IronIcon from "images/icons/iron.svg";
import ToolIcon from "images/icons/tool.svg";
import EyeIcon from "images/icons/eye.svg";
import LipIcon from "images/icons/lip.svg";
import TimeSaleIcon from "images/icons/time_sale_uncolored.svg";

import CategoryContext from "context/CategoryContext";
import {
    brandRoute,
    getStoreCategoryRoute,
    productsRoute,
    professionalPriceRoute,
    SITE_URL,
    specialOffersRoute,
    storeRoute
} from "common/const";
import TopTabBar, {NewTopTabBar} from "components/TopTabBar";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ProductScrapContext, {productScrapContextRef} from "context/ProductScrapContext";
import ProductGrid from "components/ProductGrid";
import {captureException} from "@sentry/nextjs";
import StoreNavBar from "components/layout/NavBar/StoreNavBar";
import {getReferralInfo} from "common/referral";
import Head from "next/head";
import RankingIcon from "images/icons/ranking.svg";
// import TimeSaleIcon from "images/icons/time_sale.svg";
import HairShortcutIcon from "images/icons/hair_shortcut.svg";
import MakeupShortcutIcon from "images/icons/makeup_shortcut.svg";
import BrandsIcon from "images/icons/brands.svg";
import SpecialOffers, {KEY_PROMOTION_ENDS_AT} from "pages/store/special-offers";
import {Col, Row} from "react-bootstrap";
import ProductListCard from "components/cards/ProductListCard";
import SpecialOfferProducts from "components/layout/store/products/SpecialOfferProducts";
import Layout from "components/layout/layout";
import TopBar from "components/layout/TopBar/TopBar";
import UserContext from "context/AuthContext";


const Shortcut = ({link, icon, name, shortcutIdx}) => {
    const onClick = () => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'StoreShortcutClick', {
                shortcutIdx,
                link: link,
                name: name
            }
        );
    };

    return (
        <Link href={link}>
            <a className={styles.shortcut} onClick={onClick}>
                {icon}
                <span className={styles.shortcutText}>{name}</span>
            </a>
        </Link>
    )
}


const SectionTitle = (props) => {
    const {title, hideResult, link, isSubCategory} = props;
    const count = props.count || 0;

    return (
        <div style={{display: 'block'}}>
            <div className={styles.resultContainer}>
                <div className={isSubCategory ? styles.subCategoryTitle : utilStyles.pageTitle}>{title}</div>
                {
                    !hideResult && <span>전체 {numberWithComma(count)}개</span>
                }
                {
                    link && <Link href={link}><a className={utilStyles.cursorPointer}><span
                        className={classNames(utilStyles.verticalMiddle, styles.seeMore)}>더보기</span><ArrowRightIcon
                        viewBox="0 0 16 16"
                        className={classNames(utilStyles.verticalMiddle, styles.seeMoreIcon)}/></a></Link>
                }
            </div>
        </div>
    )
};

const MD_PICK_CATEGORY = "md_pick";
const CATEGORY_TOP_PRODUCT = "category_top_products";
const COUNT_PER_PAGE = 40;


const ProductTopList = (props) => {
    const {categoryId} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [categoryProducts, setCategoryProducts] = useState([]);
    const [mdPickProducts, setMDPickProducts] = useState([]);
    const categoryContext = useContext(CategoryContext);
    const scrapContext = useContext(ProductScrapContext);
    const category = categoryContext.getCategoryById(categoryId);
    const categoryTitle = category ? category.name : '';
    const isLarge = useMediaQuery(`(min-width:${utilStyles.breakpointDesktop})`);

    useEffect(async () => {
        setIsLoading(true);
        try {
            const res = await Axios.get('/v1/store/top-products', {
                params: {category_id: categoryId || ''},
                cache: defaultCacheOption,
            });
            if (res.status < 400) {
                console.log('ProductTopList', res.data)
                setCategoryProducts(res.data[CATEGORY_TOP_PRODUCT]);
                setMDPickProducts(MD_PICK_CATEGORY in res.data ? res.data[MD_PICK_CATEGORY] : []);
                productScrapContextRef.current.addProductIds(res.data['scrapped_pids']);
            }
        } catch (e) {
            captureException(e);
        } finally {
            setIsLoading(false);
        }
    }, [categoryId]);

    return (
        isLoading ?
            <Loading style={{marginTop: 150, marginBottom: 300}}/>
            :
            <div className={styles.topListContainer}>
                {
                    !categoryId ?
                        <>
                            {/*<MainBanner />*/}
                        </>
                        :
                        <div
                            className={classNames(styles.mainSectionTitle, !isLarge ? utilStyles.sidePadding : undefined)}>
                            <SectionTitle title={categoryTitle} hideResult/>
                        </div>
                }
                {
                    mdPickProducts.length > 0 &&
                    <div key={MD_PICK_CATEGORY}
                         className={classNames(!isLarge ? utilStyles.sidePadding : undefined, styles.sectionContainer)}>
                        <SectionTitle title={"MD'S PICK"} count={0} hideResult/>
                        <ProductGrid products={mdPickProducts}/>
                    </div>
                }
                {
                    categoryProducts.map(({category, products}) =>
                        <div key={category.id}
                             className={classNames(!isLarge ? utilStyles.sidePadding : undefined, styles.sectionContainer)}>
                            <SectionTitle isSubCategory={!!categoryId} title={category.name} count={0} hideResult
                                          link={{pathname: productsRoute, query: {category: category.query_string}}}/>
                            <ProductGrid products={products}/>
                        </div>
                    )
                }
            </div>
    )
}

const useBreakpoint = (settings) => {
    const [mounted, setMounted] = useState(false);
    const value = useMediaQuery(settings);
    useEffect(() => {
        setMounted(true);
    }, []);

    return mounted ? value : false;
}

export const KEY_RANK = 'rank';
export const KEY_SELL_COUNT = 'sell_count';
export const KEY_RECENT = 'recent';
export const KEY_LOW_PRICE = 'low_price';
export const KEY_HIGH_PRICE = 'high_price';

const ProductList = (props) => {
    const [productList, setProductList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const {getCategoryById} = useContext(CategoryContext);

    const user = useContext(UserContext).user;
    const [isLoading, setIsLoading] = useState(false);
    const router = useRouter();
    const order = router.query.order;
    const categoryIndex = router.query.category;
    const page = router.query.page ? (parseInt(router.query.page) || 1) : 1;
    const categoryId = props.categoryId;
    const category = getCategoryById(categoryId) || {};
    const isLarge = useBreakpoint(`(min-width:${utilStyles.breakpointDesktop})`);
    const isMobile = useBreakpoint(`(max-width:${utilStyles.breakpointMobile})`);

    useEffect(async () => {
        if (!router.isReady || router.query.r || !order) {
            return;
        }
        setTotalCount(0);
        setProductList([]);
        try {
            setIsLoading(true);
            const params = {category_id: categoryId, order: order, page: page};
            const loggingData = Object.assign({}, params, {category: category.name});
            const res = await Axios.get('v1/store/products/', {
                params: params,
                cache: defaultCacheOption,
            });
            if (res.status < 400) {
                // console.log(res);
                const products = res.data.products.map((x, i) => {
                    x.rank = i + 1 + (page - 1) * COUNT_PER_PAGE;
                    return x
                });
                setProductList(products);
                setTotalCount(res.data.count);
                productScrapContextRef.current.addProductIds(res.data['scrapped_pids']);
                loggingData['success'] = true;
            } else {
                loggingData['success'] = false;
            }
            typeof mixpanel !== 'undefined' && mixpanel.track('ViewProductList', loggingData);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }, [categoryId, order, page, router.query.r]);

    const showRank = order === KEY_SELL_COUNT;
    const referralInfo = getReferralInfo();

    const metaTitle = (category && categoryId && categoryId.toString() !== "0" ? `${category.name} - ` : '') + '헤메코랩은 하나만 사도 어디로든 무료배송';

    const topTabBarRef = useRef();

    const logListOrderChange = (order) => {
        typeof mixpanel !== 'undefined' && mixpanel.track('StoreListOrderChange', {order: order, category: category.name});
    };

    const onProductClick = (page, product, productIndex) => {
        typeof mixpanel !== 'undefined' && mixpanel.track(
            'StoreProductClick',
            {productId: product.id, productName: product.name, productIndex, page}
        );
    };

    return (
        <>
            <Head>
                <meta name="description" content="하나만 사도 어디로든 무료배송" key="description"/>
                <title>{metaTitle}</title>
                <meta property="og:type" content="website" key="ogType"/>
                <meta property="og:title" content={metaTitle} key="ogTitle"/>
                <meta property="og:description" content="하나만 사도 어디로든 무료배송" key="ogDescription"/>
                <meta key="ogImage"/>

                <link rel="icon" href="/favicon.ico" type="image/x-icon"/>
                <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon"/>
            </Head>
            {/*{*/}
            {/*    !currentCategory &&*/}
            {/*    <MainBanner />*/}
            {/*}*/}
            {/*<div className={styles.topMargin} />*/}
            {/*<BreadCrumb />*/}
            <div/>
            <StoreNavBar replace={true} />
            {
                categoryIndex === '0' &&
                <>
                    <div className={styles.shortcutSection}>
                        <div className={styles.shortcutWrapper}>
                            {
                                !!user && !!user.pro_grade ?
                                    <Shortcut link={professionalPriceRoute} icon={<img src={'https://d1cnx04b8cgzcv.cloudfront.net/pricetag_color.png'} style={{width: 38, height: 38, marginBottom: 2}} />} name={"PRO특가"} shortcutIdx={0}/>
                                    :
                                    <Shortcut link={specialOffersRoute} icon={<TimeSaleIcon/>} name={"헤메코특가"} shortcutIdx={0}/>
                            }
                            <Shortcut link={getStoreCategoryRoute('립')} icon={<LipIcon/>} name={"립"} shortcutIdx={1}/>
                            <Shortcut link={getStoreCategoryRoute('아이')} icon={<EyeIcon/>} name={"아이"} shortcutIdx={2}/>
                            <Shortcut link={getStoreCategoryRoute('메이크업_툴/디바이스')} icon={<ToolIcon/>} name={"뷰티툴"}
                                      shortcutIdx={3}/>
                            <Shortcut link={getStoreCategoryRoute('트리트먼트/팩')} icon={<TreatmentIcon/>} name={"트리트먼트"}
                                      shortcutIdx={4}/>
                            <Shortcut link={getStoreCategoryRoute('세럼/에센스/오일')} icon={<HairEssenceIcon/>} name={"헤어에센스"}
                                      shortcutIdx={5}/>
                            <Shortcut link={getStoreCategoryRoute('팩/패드')} icon={<MaskPackIcon/>} name={"마스크팩"}
                                      shortcutIdx={6}/>
                            <Shortcut link={getStoreCategoryRoute('헤어_고데기')} icon={<IronIcon/>} name={"고데기"}
                                      shortcutIdx={7}/>
                        </div>
                    </div>
                    <div className={utilStyles.mobileBorder}/>
                    <div className={styles.specialOfferSection}>
                        <SpecialOfferProducts topTabBarRef={topTabBarRef}/>
                    </div>
                    <div className={utilStyles.mobileBorder}/>
                </>
            }
            {/*<div className={styles.titleTopMargin} />*/}
            {/*<div className={!isLarge ? utilStyles.sideMargin : 0}>*/}
            {/*    <SectionTitle title={categoryId ? category.name : 'ALL'} count={totalCount} hideCount />*/}
            {/*</div>*/}
            {/*<div className={styles.topTabBarMargin} />*/}
            <div ref={topTabBarRef}
                 className={classNames(styles.topTabBarWrapper, !isMobile ? utilStyles.sidePadding : undefined)}>
                <NewTopTabBar
                    smallText
                    activeRoute={order}
                    tabs={[
                        {
                            route: KEY_RANK, title: '추천순', onClick: () => {
                                logListOrderChange(KEY_RANK);
                                router.replace({
                                    query: Object.assign({}, router.query, {
                                        order: KEY_RANK,
                                        page: order === KEY_RANK ? page : 1
                                    })
                                }, undefined, {shallow: true, scroll: true});
                            }
                        },
                        {
                            route: KEY_SELL_COUNT, title: '판매량순', onClick: () => {
                                logListOrderChange(KEY_SELL_COUNT);
                                router.replace({
                                    query: Object.assign({}, router.query, {
                                        order: KEY_SELL_COUNT,
                                        page: order === KEY_SELL_COUNT ? page : 1
                                    })
                                }, undefined, {shallow: true, scroll: true});
                            }
                        },
                        {
                            route: KEY_RECENT, title: '최신순', onClick: () => {
                                logListOrderChange(KEY_RECENT);
                                router.replace({
                                    query: Object.assign({}, router.query, {
                                        order: KEY_RECENT,
                                        page: order === KEY_RECENT ? page : 1
                                    })
                                }, undefined, {shallow: true, scroll: true});
                            }
                        },
                        {
                            route: KEY_LOW_PRICE, title: '낮은가격순', onClick: () => {
                                logListOrderChange(KEY_LOW_PRICE);
                                router.replace({
                                    query: Object.assign({}, router.query, {
                                        order: KEY_LOW_PRICE,
                                        page: order === KEY_LOW_PRICE ? page : 1
                                    })
                                }, undefined, {shallow: true, scroll: true});
                            }
                        },
                        {
                            route: KEY_HIGH_PRICE, title: '높은가격순', onClick: () => {
                                logListOrderChange(KEY_HIGH_PRICE);
                                router.replace({
                                    query: Object.assign({}, router.query, {
                                        order: KEY_HIGH_PRICE,
                                        page: order === KEY_HIGH_PRICE ? page : 1
                                    })
                                }, undefined, {shallow: true, scroll: true});
                            }
                        },
                    ]}
                />
            </div>
            {/*<div className={styles.productGridMargin} />*/}
            <div className={utilStyles.sidePadding}>
                {
                    isLoading ?
                        <Loading style={{marginTop: 150, marginBottom: 300}}/>
                        :
                        <ProductGrid
                            products={productList} showRank={showRank} referralInfo={referralInfo}
                            onProductClick={(product, productIdx) => onProductClick(page, product, productIdx)}
                        />
                }
                <>
                    <div className={styles.paginatorMargin}/>
                    <Paginator
                        isLoading={isLoading}
                        currentPage={page} size={isLarge ? 10 : 5}
                        onChangeCurrentPage={(e) => {
                            typeof mixpanel !== 'undefined' && mixpanel.track('StorePageChange', {
                                prevPage: page,
                                nextPage: e,
                            });
                            router.replace({query: Object.assign({}, router.query, {page: e})}, undefined, {
                                shallow: true,
                                scroll: true
                            });
                        }}
                        totalCount={totalCount} countPerPage={COUNT_PER_PAGE}/>
                    <div className={styles.pageBottomMargin}/>
                </>
            </div>
        </>
    )
}

export const HASH_PRODUCT_LIST = 'product-list';


export default function Products() {
    const router = useRouter();
    const {getCategoryIdByName, productCategories} = useContext(CategoryContext);
    const category = router.query.category;
    const order = router.query.order;
    const user = useContext(UserContext).user;
    const categorySplitList = !!category ? category.split('_') : [];
    const categoryIdOrName = categorySplitList[categorySplitList.length - 1];

    useEffect(async () => {
        if (!router.isReady || router.query.r || productCategories.length === 0) {
            return;
        }
        if (isNaN(categoryIdOrName)) {
            const categoryId = getCategoryIdByName(category);
            console.log('categoryId', categoryId, categoryIdOrName, category);
            router.replace({
                query: Object.assign({}, router.query, {category: categoryId}),
                hash: window.location.hash,
            }, undefined, {shallow: false});
            return;
        }
        if (!order) {
            router.replace({
                query: Object.assign({}, router.query, {order: KEY_RANK}),
                hash: window.location.hash,
            }, undefined, {shallow: false});
        }
    }, [router.isReady, categoryIdOrName, order, router.query.r, productCategories]);

    return (
        <Layout topBar={<TopBar search pouch isPro={!!user && user.pro_grade} />}>
            <Head>
                <link rel="canonical" href={`${SITE_URL}/store/products?category=${router.query.category || 0}`}/>
            </Head>
            {
                !router.isReady && isNaN(categoryIdOrName) ?
                    <div/>
                    :
                    <ProductList categoryId={categoryIdOrName}/>
                // categorySplitList.length === 2 ?
                //     <ProductList categoryId={categorySplitList[categorySplitList.length - 1]} />
                //     :
                //     <ProductTopList categoryId={categorySplitList.length > 0 ? categorySplitList[categorySplitList.length - 1] : null} />

            }
        </Layout>
    )
}

Products.restoreScroll = true;
