import {useEffect, useState} from "react";
import {KEY_PROMOTION_ENDS_AT} from "pages/store/special-offers";
import {Axios, defaultCacheOption} from "api";
import {productScrapContextRef} from "context/ProductScrapContext";
import styles from "pages/store/products/index.module.scss";
import Link from "next/link";
import {specialOffersRoute} from "common/const";
import GrayArrowRightIcon from "images/icons/arrow_right_black.svg";
import ProductListCard from "components/cards/ProductListCard";
import {HASH_PRODUCT_LIST} from "pages/store/products";
import {getScrollKeyForRouteKey, shuffle} from "common/utils";


const NUM_SPECIAL_OFFER_PRODUCTS = 10;

const SpecialOfferProducts = (props) => {
    const {topTabBarRef, fontSize} = props;
    const [isLoading, setIsLoading] = useState(false);
    const [products, setProducts] = useState([]);

    const setIsDone = props.setIsDone || (() => {});

    const onProductClick = props.onProductClick || (
        (product, productIndex) => {
            typeof mixpanel !== 'undefined' && mixpanel.track(
                'StoreSpecialOfferProductClick',
                {productId: product.id, productName: product.name, productIndex}
            );
        }
    );

    const onLinkClick = props.onLinkClick || (() => {
        typeof mixpanel !== 'undefined' && mixpanel.track('StoreSpecialOfferClick');
    });

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            setIsLoading(true);
            const params = {category_id: 0, order: KEY_PROMOTION_ENDS_AT, size: NUM_SPECIAL_OFFER_PRODUCTS};
            const res = await Axios.get('v1/store/special-offer-products/', {
                params: params,
                cache: defaultCacheOption,
            });
            if (res.status < 400) {
                const products = res.data.products;
                setProducts(products);
                setIsDone(true);
                productScrapContextRef.current.updateByProductIdsAndScrappedProductIds(
                    res.data.products.map(e => e.id), res.data['scrapped_pids'],
                );
            }
        } catch (e) {
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (!topTabBarRef || !topTabBarRef.current) return;
        if (products.length > 0) {
            if (window.location.hash.includes(HASH_PRODUCT_LIST)) {
                if (!sessionStorage.getItem(getScrollKeyForRouteKey(window.history.state.key))) {
                    window.requestAnimationFrame(() => {
                        topTabBarRef.current.scrollIntoView();
                    });
                }
            }
        }
    }, [products, topTabBarRef]);

    return (
        !isLoading
            ?
            <>
                <Link href={specialOffersRoute}>
                    <a className={styles.sectionTitleWrapper} onClick={onLinkClick}>
                        <span className={styles.sectionTitle} style={{fontSize}}>헤메코특가</span>
                        <GrayArrowRightIcon viewBox="0 0 12 12" width={20} height={20}/>
                    </a>
                </Link>
                <div className={styles.productsWrapper}>
                    <div className={styles.horizontalScrollPaddingLeft} />
                    {
                        products.slice(0, NUM_SPECIAL_OFFER_PRODUCTS).map((e, index) => {
                            return (
                                <div className={styles.cardWrapper} key={index}>
                                    <ProductListCard
                                        product={e} showPromotionDaysLeft
                                        onClick={() => onProductClick(e, index)}
                                    />
                                </div>
                            )
                        })
                    }
                    <div className={styles.horizontalScrollPaddingRight} />
                </div>
            </>
            :
            <></>
    )
};

export default SpecialOfferProducts;
